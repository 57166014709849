import Glide from '@glidejs/glide';
import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

  if( $( '.glide' ).length ) {

    var ammenitiesSlider = document.querySelectorAll( '.ammenities-slider' );

    if( $( ammenitiesSlider ).length ){
      for ( var i = 0; i < ammenitiesSlider.length; i++ ) {
        new Glide( ammenitiesSlider[i], {
          type: 'carousel',
          animationDuration: 600,
          perTouch: 1,
          perView: 1, 
          touchRatio: 1,
          } ).mount()
        }
      }

    if( $( '.scrolling-glide' ).length ) {
      //Scrolling Gallery
      let scrollSlider = document.querySelectorAll( '.scrolling-glide' )
  
      scrollSlider.forEach( ( elem ) => {
        new Glide( elem, {
          type: 'carousel',
          startAt: 0,
          perView: 3,
          peek: 108,
          hoverpause: true,
          gap: 54,
          autoplay: 2000,
          animationTimingFunc: 'ease-out',
          breakpoints: {
            1025: {
              gap: 32,
              perView: 2,
              peek: 50,
            },
            767: {
              gap: 16,
              perView: 1,
              peek: 40,
            }
          }
        } ).mount()
      } )
    }

    if( $( '.hw-glide' ).length ) {
      // Half Width Slider
      let hwSlider = document.querySelectorAll( '.hw-glide' )
  
      hwSlider.forEach( ( elem ) => {
        new Glide( elem, {
          type: 'carousel',
          startAt: 0,
          perView: 1,
          perTouch: 1,
          focusAt: 'center',
          peek: 600,
          gap: 80,
          breakpoints: {
            1850: {
              peek: 400,
              gap: 50,
            },
            1320: {
              peek: 250,
              gap: 24,
            },
            920: {
              peek: 100,
            },
            580: {
              peek: 16,
              gap: 8
            }
          }
        } ).mount()
      } )
    }
    
    if( $( '.info-glide' ).length ) {
      // Info Slider
      let hwSlider = document.querySelectorAll( '.info-glide' )
  
      hwSlider.forEach( ( elem ) => {
        new Glide( elem, {
          type: 'carousel',
          startAt: 0,
          perView: 1,
          gap: 0,
          peek: 0,
          focusAt: 'center'
        } ).mount()
      } )
    }

    if( $( '.fw-image' ).length ) {
      // Full Width Slider
      let fwImage = document.querySelectorAll( '.fw-image' )
      let fwNav = document.querySelectorAll( '.fw-nav' )
      let fwDescript = document.querySelectorAll( '.fw-description' )
  
      let i;
  
      for( i=0; i<fwImage.length; i++ ) {
        let FWImage = new Glide( fwImage[i], {
          type: 'carousel',
          startAt: 0,
          perView: 1,
          gap: 0,
          peek: 0,
          focusAt: 'center'
        } )
  
        let FWDescript = new Glide( fwDescript[i], {
          type: 'carousel',
          startAt: 0,
          perView: 1,
        } )

        let FWNav;
  
        if( $( fwNav[i] ).hasClass( 'slider' ) ) {
          FWNav = new Glide( fwNav[i], {
            type: 'slider',
            startAt: 0,
            perView: 4, 
            gap: 10,
            breakpoints: {
              900: {
                perView: 2
              },
              426: {
                perView: 1,
                gap: 0,
              }
            }
          } )
        } else {
          FWNav = new Glide( fwNav[i], {
            type: 'carousel',
            startAt: 0,
            perView: 3, 
            gap: 20,
            focusAt: 'center',
            breakpoints: {
              900: {
                perView: 2
              },
              426: {
                perView: 1,
                gap: 0,
              }
            }
          } )
        }
  
        FWImage.mount();
        FWDescript.mount();
  
        //run this after mount so the clones are appended when you query select all the slider nav items
        FWNav.on('mount.after', function() {
          $( '.fw-nav-item' ).on( 'click', function( e ) {
            e.preventDefault();
            FWImage.go( '='+(e.target.dataset.index ) ) 
            FWDescript.go( '='+(e.target.dataset.index ) ) 
          })
        });
  
        FWNav.mount();
  
        $( '.glide__arrow' ).on(  'click', () => {
          FWImage.go( '='+( FWNav.index ) )
          FWDescript.go( '='+( FWNav.index ) )
        })
  
        FWImage.on( 'swipe.end', () => {
          FWNav.go( '='+( FWImage.index ) )
          FWDescript.go( '='+( FWImage.index ) )
        })
        
        FWImage.on( 'move.after', () => {
          FWNav.go( '='+( FWImage.index ) )
          FWDescript.go( '='+( FWImage.index ) )
        })
      }
    }

    if( $( '.un-glide' ).length ) {
      let utilityNavGlide = document.querySelector( '.un-glide' )
  
      let utilityNav = new Glide( utilityNavGlide, {
        type: 'slider',
        startAt: 0,
        perView: 7,
        perTouch: 1,
        perSwipe: 1,
        breakpoints: {
          1235: {
            perView: 1,
          },
        }
      } )
  
      function resizeFunction() {
          var windowSize = window.innerWidth;
          
          if (windowSize > 1235) {
            utilityNav.disable();
            setTimeout( () => {
              $('.un-glide .link-wrapper').css({
                transform: 'translate3d(0px, 0px, 0px)'
              })
            }, 100 )
          } else {
            utilityNav.update();
            utilityNav.enable();
          }
      };
  
      window.addEventListener("resize", resizeFunction);

      const glideArrowLeft = document.querySelector('.glide__arrow--left')
      const glideArrowRight = document.querySelector('.glide__arrow--right')

      glideArrowLeft.addEventListener('click', function() {
        goLeft();
      })
              
      glideArrowRight.addEventListener('click', function() {
        goRight();
      })
      
      function goLeft() {
        utilityNav.go('<');
      }
      function goRight() {
        utilityNav.go('>');
      }
      function goID(i) {
        utilityNav.go('='+i);
      }
      
      if( $( '.utility-nav-section' ).length ) {
        gsap.registerPlugin( ScrollTrigger );
        const navLink = document.querySelectorAll( '.util-nav-link' )

        ScrollTrigger.matchMedia({ 
          "(min-width: 1235px)": function () {
            navLink.forEach( ( elem, i ) => {
              let linkID = $( elem ).attr('href')

              ScrollTrigger.create( {
                trigger: linkID,
                start: 'top-=120px top',
                end: 'bottom-=120px top',
                toggleClass: { targets: elem, className: 'active-link' },
                invalidateOnRefresh: true,
                // markers: true,
              } )
            })
          },
      
          "(max-width: 1234px)": function() {
            navLink.forEach( ( elem, i ) => {
              let linkID = $( elem ).attr('href')

              ScrollTrigger.create( {
                trigger: linkID,
                start: 'top-=120px top',
                end: 'bottom-=120px top',
                toggleClass: { targets: elem, className: 'active-link' },
                onEnter: () => goID(i),
                onLeaveBack: () => goID( i -1 ),
                invalidateOnRefresh: true,
                // markers: true,
              } )

              elem.addEventListener('click', function() {
                goID(i);
              })
            })
          }
        })
      }

      if( $( '.utility-nav-section-2' ).length ) {
        gsap.registerPlugin( ScrollTrigger );
        const navLink = document.querySelectorAll( '.util-nav-link' )

        ScrollTrigger.matchMedia({ 
          "(min-width: 768px)": function () {
            navLink.forEach( ( elem, i ) => {
              let linkID = $( elem ).attr('href')

              ScrollTrigger.create( {
                trigger: linkID,
                start: 'top-=120px top',
                end: 'bottom-=120px top',
                toggleClass: { targets: elem, className: 'active-link' },
                invalidateOnRefresh: true,
                // markers: true,
              } )
            })
          },
      
          "(max-width: 767px)": function() {
            navLink.forEach( ( elem, i ) => {
              let linkID = $( elem ).attr('href')

              ScrollTrigger.create( {
                trigger: linkID,
                start: 'top-=120px top',
                end: 'bottom-=120px top',
                toggleClass: { targets: elem, className: 'active-link' },
                onEnter: () => goID(i),
                onLeaveBack: () => goID( i -1 ),
                invalidateOnRefresh: true,
                // markers: true,
              } )

              elem.addEventListener('click', function() {
                goID(i);
              })
            })
          }
        })
      }


      utilityNav.mount()
      window.addEventListener('load', function () {
        utilityNav.update()
      });
    }

    if( $( '.small-glide' ).length ) {
      let smGlide = document.querySelector( '.small-glide' )

      new Glide( smGlide, {
        type: 'carousel',
        startAt: 0,
        perView: 3,
        gap: 24,
        breakpoints: {
          767: {
            perView: 1,
          }
        }
      } ).mount()
    }

    //this is to dyanimally assign the min height on glide sliders
    let sliders = $( '.glide' );

    $( sliders ).each( function () {
      let sliderItems = $( this ).find( '.glide__slide' )
      let divHeights = [];

      $( sliderItems ).each( function ( i ) {
        divHeights.push( $( sliderItems[ i ] ).height() );
        
      })
      divHeights.sort();
      let height = divHeights[ divHeights.length - 1 ];
      $( '.glide__slides-fade' ).css( 'min-height', height + 'px' );   
    })
  }