import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import $ from 'jquery';


let ScrollTriggerInit = gsap.registerPlugin( ScrollTrigger );

if( $( '.sliding-text, .text-block-marquee-section, .h-scroll, .sbs-container, .hero', '#map' ).length ) {
  ScrollTriggerInit;
}

// ##     ## ######## ########   #######  
// ##     ## ##       ##     ## ##     ## 
// ##     ## ##       ##     ## ##     ## 
// ######### ######   ########  ##     ## 
// ##     ## ##       ##   ##   ##     ## 
// ##     ## ##       ##    ##  ##     ## 
// ##     ## ######## ##     ##  #######  

if( $( '.hero.full' ).length ) {
  const loop = () => {
    let listItems = $('.fading-title').length;
    let count = 1;
    let finalTitle = $( '.final-title' );

    setInterval( () => {
      $('.fading-title.fade-in-out').removeClass('fade-in-out');
      $('.fading-title:eq(' + count +   ')').addClass('fade-in-out');	

      count += 1;

      if ( finalTitle.length ) {
        finalTitle.removeClass('fade-in-out');
        $( '.initial-title' ).addClass( 'fade-in' );
        $( '.initial-title' ).removeClass( 'fade-out' );

        if (count >= listItems + 1) {
          finalTitle.addClass('fade-in-out');
          $( '.initial-title' ).removeClass('fade-in');
          $( '.initial-title' ).addClass('fade-out');
          count = 0;
        }
      } else {
        if (count >= listItems) {
          count = 0;
        }
      }
    }, 4000);
  }
  
  $( '.initial-title' ).addClass( 'fade-in' );
  $('.fading-title:eq(0)').addClass('fade-in-out');	
  loop();

  const vid = document.querySelector('.hero-video');

  if( $( vid ).length ){
    ScrollTrigger.create( { 
      trigger: $( '.hero.full' ),
        start: 'center center',
        end: 'bottom top',
        scrub: 1,
        onEnterBack: () => vid.play(),
        onLeave: () => vid.pause()
    } );
  }
}

if( $( '.hero.half' ).length ) {
  let halfHero = document.querySelector( '.hero.half' )

  let heroAnimate = gsap.timeline( {
    scrollTrigger: {
      trigger: halfHero,
      start: 'top top',
      end: 'center+=100 top',
      scrub: 1,
    }
  } )

  heroAnimate.to( $( halfHero ).find( '.house-svg' ), { yPercent: -40 }, 0 )
  heroAnimate.to( $( halfHero ).find( '.up-tri' ), { rotate: 40 }, 0 )
  heroAnimate.to( $( halfHero ).find( '.down-tri' ), { rotate: 30 }, 0 )
}


if( $( '.hero.split' ).length ) {

  let fadeIn = document.querySelectorAll( '.fade-item' );
  fadeIn.forEach((elem, i) => {
    $( elem ).addClass( 'fade-in' );
  });
}


// ########  #######   #######  ######## ######## ########  
// ##       ##     ## ##     ##    ##    ##       ##     ## 
// ##       ##     ## ##     ##    ##    ##       ##     ## 
// ######   ##     ## ##     ##    ##    ######   ########  
// ##       ##     ## ##     ##    ##    ##       ##   ##   
// ##       ##     ## ##     ##    ##    ##       ##    ##  
// ##        #######   #######     ##    ######## ##     ## 

if( $( '.footer .logo-animation' ).length ) {
  let footerCircle = document.querySelector( '.footer .logo-animation' )
  
  ScrollTrigger.create( {
    trigger: $( footerCircle ).parents( '.footer' ),
    start: "top bottom",
    end: "bottom top",
    onEnter: () => $( footerCircle ).addClass( 'animate' ),
    onLeaveBack: () => $( footerCircle ).removeClass( 'animate' )
  } )
}


// ##     ##  #######  ########  #### ########  #######  ##    ## ########    ###    ##           ######   ######  ########   #######  ##       ##       
// ##     ## ##     ## ##     ##  ##       ##  ##     ## ###   ##    ##      ## ##   ##          ##    ## ##    ## ##     ## ##     ## ##       ##       
// ##     ## ##     ## ##     ##  ##      ##   ##     ## ####  ##    ##     ##   ##  ##          ##       ##       ##     ## ##     ## ##       ##       
// ######### ##     ## ########   ##     ##    ##     ## ## ## ##    ##    ##     ## ##           ######  ##       ########  ##     ## ##       ##       
// ##     ## ##     ## ##   ##    ##    ##     ##     ## ##  ####    ##    ######### ##                ## ##       ##   ##   ##     ## ##       ##       
// ##     ## ##     ## ##    ##   ##   ##      ##     ## ##   ###    ##    ##     ## ##          ##    ## ##    ## ##    ##  ##     ## ##       ##       
// ##     ##  #######  ##     ## #### ########  #######  ##    ##    ##    ##     ## ########     ######   ######  ##     ##  #######  ######## ######## 
                                                                                                                                    
if ( $( '.h-scroll' ).length ) {

  let hScroll = document.querySelectorAll(".h-scroll");

  hScroll.forEach( (hScrollelem) => {

    // Horizontal Scrolling Container
    const sections = gsap.utils.toArray($( hScrollelem ).children());
    let scrollTween;
    let maxWidth = 0;

    sections.forEach((section) => {
      maxWidth += section.scrollWidth;
    });

    ScrollTrigger.matchMedia({
      "(min-width: 768px)": function () {
        let offset = hScrollelem.offsetWidth;

        scrollTween = gsap.to(hScrollelem , {
          x: () => -( maxWidth - document.documentElement.clientWidth ) + "px",
          ease: "none", // <-- IMPORTANT!
          scrollTrigger: {
            trigger: hScrollelem,
            invalidateOnRefresh: true,
            pin: true,
            id: 'h-scroll',
            scrub: 2,
            start: () => 'top top',
            end: () => '+=' + offset + ' top',
            // markers: true,
          }
        });

        $( window ).on('hashchange', function() {
          hashScroll(); 
        });
      },

      "(max-width: 767px)": function() {
        scrollTween = gsap.to(hScrollelem , {
          x: () => 0,
          ease: "none", // <-- IMPORTANT!
          scrollTrigger: {
            trigger: hScrollelem,
            pin: false,
            scrub: 1,
            end: () => "+=" + hScrollelem.offsetWidth
          }
        });

        $( window ).on('hashchange', function() {
          hashScroll(); 
        });
      }
    })

    let hashScroll = () => {
      let sideScroll = ScrollTrigger.getById( 'sideScroll' )
      let hashID = window.location.hash.replace( '#', '' )
      let pinSpacerPosition = $( '.pin-spacer' ).position().top;
      let sectionID = $( ScrollTrigger.getById( 'sideScroll' ).trigger).attr('id')

      if ( hashID == sectionID) {
        sideScroll.scroll(pinSpacerPosition); 
      }
    }

    // Image Parallax
    function imageParallax( elem, trigger, amount) {
      ScrollTrigger.matchMedia({ 
        "(min-width: 768px)": function () {
          gsap.to( elem, {
            xPercent: amount,
            scrollTrigger: {
              trigger: trigger,
              containerAnimation: scrollTween,
              invalidateOnRefresh: true,
              start: 'left left',
              end: 'right left',
              scrub: 2,
            }
          } )
        },
  
        "(max-width: 767px)": function() {
          gsap.to( elem, {
            yPercent: amount,
            scrollTrigger: {
              trigger: trigger,
              start: 'top top',
              end: 'bottom top',
              scrub: 2,
            }
          } )
        }
      })
    }

    // Side by side
    if( $( '.inside-h-scroll-single' ).length ) {
      let singleSBSImage = document.querySelectorAll( '.inside-h-scroll-single .content-container .image-container img' )
      let imageTri = document.querySelectorAll( '.single .content-container .transparent-triangle' )
      let blueTri = document.querySelectorAll( '.single .blue-triangle' )
    
      singleSBSImage.forEach( ( elem ) => {
        imageParallax( elem, $( elem ).parents( '.content-container' ), -50 )
      } )
  
      ScrollTrigger.matchMedia({
        "(min-width: 768px)": function () {
          imageTri.forEach( ( elem ) => {
            gsap.to( elem, {
              left: 0,
              top: 0,
              scrollTrigger: {
                trigger: elem,
                containerAnimation: scrollTween,
                invalidateOnRefresh: true,
                start: 'left left',
                end: 'right left',
                scrub: 1
              }
            } )
          } )
      
          blueTri.forEach( ( elem ) => {
            gsap.to( elem, {
              right: 0,
              scrollTrigger: {
                trigger: elem,
                containerAnimation: scrollTween,
                invalidateOnRefresh: true,
                start: 'left center',
                end: 'right left',
                scrub: 1
              }
            } )
          } )
        },
  
        "(max-width: 767px)": function() {
          imageTri.forEach( ( elem ) => {
            gsap.to( elem, {
              left: 0,
              top: 0,
              scrollTrigger: {
                trigger: elem,
                start: 'top center',
                end: 'bottom top',
                scrub: 1
              }
            } )
          } )
  
      
          blueTri.forEach( ( elem ) => {
            gsap.to( elem, {
              right: 0,
              scrollTrigger: {
                trigger: elem,
                start: 'top center',
                end: 'bottom top',
                scrub: 1
              }
            } )
          } )
        }
      })
    }
  
    // Half Width
    if( $( '.hw-graphic-container' ).length ) {
      let hwImage = document.querySelectorAll( '.hw-graphic-container .content-container .image-container img' );
      let houseSVG = document.querySelectorAll( '.hw-graphic-container .content-container .house-svg' );
      let upTri = document.querySelectorAll( '.hw-graphic-container .content-container .up-tri' );
      let downTri = document.querySelectorAll( '.hw-graphic-container .content-container .down-tri' );
  
      function rotateSVG( elem, rotate ) {
        ScrollTrigger.matchMedia({
          "(min-width: 768px)": function () {
            gsap.to( elem, {
              rotation: rotate,
              scrollTrigger: {
                trigger: elem,
                containerAnimation: scrollTween,
                invalidateOnRefresh: true,
                start: 'left center',
                end: 'right left',
                scrub: 2
              }
            } )
          },
  
          "(max-width: 767px)": function() {
            gsap.to( elem, {
              rotation: rotate,
              scrollTrigger: {
                trigger: elem,
                start: 'top center+=200',
                end: 'bottom top',
                scrub: 2,
              }
            } )
          }
        })
      }
      
      // Image and Graphic Animation
      hwImage.forEach( ( elem ) => {
        imageParallax( elem, $( elem ).parents( '.hw-graphic-container' ), -50 )
  
        // Animation to make image grow as you scroll
        ScrollTrigger.matchMedia({
          "(min-width: 768px)": function () {
            gsap.to( $( elem ).parent(), {
              maxWidth: 350,
              scrollTrigger: {
                trigger: $( elem ).parents( '.hw-graphic-container' ),
                containerAnimation: scrollTween,
                invalidateOnRefresh: true,
                start: 'left center+=200',
                end: 'center left',
                scrub: 1
              }
            } )
          },
  
          "(max-width: 767px)": function() {
            gsap.to( $( elem ).parent(), {
              maxWidth: 300,
              scrollTrigger: {
                trigger: $( elem ).parents( '.hw-graphic-container' ),
                start: 'top center',
                end: 'center top',
                scrub: 1,
              }
            } )
          }
        })
      } )
  
      houseSVG.forEach( ( elem ) => {
        ScrollTrigger.matchMedia({
          "(min-width: 768px)": function () {
            gsap.to( elem, {
              yPercent: 25,
              scrollTrigger: {
                trigger: elem,
                containerAnimation: scrollTween,
                invalidateOnRefresh: true,
                start: 'left center',
                end: 'right left',
                scrub: 2
              }
            } )
          },
  
          "(max-width: 767px)": function() {
            gsap.to( elem, {
              yPercent: 25,
              scrollTrigger: {
                trigger: elem,
                start: 'top center',
                end: 'bottom top',
                scrub: 2
              }
            } )
          }
        })
      } )
  
      upTri.forEach( ( elem ) => {
        rotateSVG( elem, 25 )
      } )
  
      downTri.forEach( ( elem ) => {
        rotateSVG( elem, -25 )
      } )
    }
  
    // Parallax Images
    if( $( '.parallax-image-section' ).length ) {
      let largeImage = document.querySelectorAll( '.parallax-image-section .large-image-container img' )
      let smallImage = document.querySelectorAll( '.parallax-image-section .small-image-container img' )
  
      // Small Image Animation
      function smallImageParallax( elem, trigger, deskAmount, phoneAmount ) {
        ScrollTrigger.matchMedia({
          "(min-width: 768px)": function () {
            gsap.to( elem, {
              xPercent: deskAmount,
              scrollTrigger: {
                trigger: trigger,
                containerAnimation: scrollTween,
                invalidateOnRefresh: true,
                start: 'left center',
                end: 'center center',
                scrub: 2,
              }
            } )
          },
    
          "(max-width: 767px)": function() {
            gsap.to( elem, {
              yPercent: phoneAmount,
              scrollTrigger: {
                trigger: trigger,
                start: 'top center',
                end: 'center center',
                scrub: 2,
              }
            } )
          }
        })
      }
  
      largeImage.forEach( ( elem ) => {
        imageParallax( elem, elem, -20 )
  
        gsap.to( $( elem ).parent(), {
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
          scrollTrigger: {
            trigger: $( elem ).parent(),
            containerAnimation: scrollTween,
            invalidateOnRefresh: true,
            start: 'left center',
            end: 'center center',
            scrub: 2,
            // markers: true, 
            // id: 'large'
          }
        } )
      } )
    
      smallImage.forEach( ( elem ) => {
        smallImageParallax( $( elem ).parent(), $( elem ).parents( '.image-wrapper' ), -10, 15 )
      } )
    }

    // Text Block Marquee
    if( $( '.text-slide-wrapper' ).length ) {
      const marqueeText = document.querySelectorAll( '.text-slide-wrapper' )
  
      // Vertical Marquee Animation
      function marqueeTextAnimate( elem ) {
        ScrollTrigger.matchMedia({
          "(min-width: 768px)": function () {
            // Desktop Marquee text is vertical
            gsap.fromTo( elem, { yPercent: 0 }, {
              yPercent: -25,
              scrollTrigger: {
                trigger: $( elem ).parents( '.text-block-section' ),
                containerAnimation: scrollTween,
                invalidateOnRefresh: true,
                start: 'left center',
                end: 'right left',
                scrub: 3
              }
            } )
          },
    
          "(max-width: 767px)": function() {
            // Text scrolls on the x-axis
            gsap.fromTo( elem, { xPercent: 0 }, {
              xPercent: -25,
              scrollTrigger: {
                trigger: $( elem ).parents( '.text-block-section' ),
                start: 'top center',
                end: 'bottom top',
                scrub: 3
              }
            } )
          }
        })
      }
      
      marqueeText.forEach( ( elem ) => {
        marqueeTextAnimate( elem )
      } )
    }

    // Column Block
    if( $( '.column-block-container' ).length ) {
      let columnBlock = document.querySelectorAll( '.column-block-container' )
  
      columnBlock.forEach( ( elem ) => {
  
        let columnAnimateDesktop = gsap.timeline( {
          scrollTrigger: {
            trigger: elem,
            start: 'left left',
            end: 'right left',
            containerAnimation: scrollTween,
            invalidateOnRefresh: true,
            scrub: 2,
          }
        } )
  
        let columnAnimateMobile = gsap.timeline( {
          scrollTrigger: {
            trigger: elem,
            start: 'top center',
            end: 'bottom top',
            scrub: 2,
          }
        } )
  
        ScrollTrigger.matchMedia({
          "(min-width: 768px)": function () {
            if( $( elem ).find( '.info-container' ).length ) {
              columnAnimateDesktop.to( $( elem ).find( '.info-container' ), { xPercent: 10 }, 0)
            }
            if( $( elem ).find( '.image-container img' ).length ) {
              columnAnimateDesktop.to( $( elem ).find( '.image-container img' ), { xPercent: -10 }, 0)
            }
            if( $( elem ).find( '.video-container' ).length ) {
              columnAnimateDesktop.to( $( elem ).find( '.video-container' ), { xPercent: 15 }, 0 )
            }
            if( $( elem ).find( '.top-image-container img' ).length ) {
              columnAnimateDesktop.to( $( elem ).find( '.top-image-container img' ), { xPercent: -15 }, 0 )
            }
            if( $( elem ).find( '.side-image-container' ).length ) {
              columnAnimateDesktop.to( $( elem ).find( '.side-image-container img' ), { xPercent: -20 }, 0 )
              columnAnimateDesktop.to( $( elem ).find( '.side-image-container .dark-tri' ), { xPercent: -120 }, 0 )
            }
          },
    
          "(max-width: 767px)": function() {
            if( $( elem ).find( '.side-image-container' ).length ) {
              columnAnimateMobile.to( $( elem ).find( '.side-image-container img' ), { yPercent: -20 }, 0 )
              columnAnimateMobile.to( $( elem ).find( '.side-image-container .dark-tri' ), { xPercent: -120 }, 0 )
            }
            if( $( elem ).find( '.image-container img' ).length ) {
              columnAnimateMobile.to( $( elem ).find( '.image-container img' ), { yPercent: -10 }, 0)
            }
          }
        })
      } )
    }

    // Long Info Banner
    if( $( '.info-banner' ).length ) {
      let infoBanner = document.querySelectorAll( '.info-banner' )

      infoBanner.forEach( ( elem ) => {
          
        let bannerAnimateDesktop = gsap.timeline( {
          scrollTrigger: {
            trigger: elem,
            start: 'left left',
            end: 'right left',
            containerAnimation: scrollTween,
            invalidateOnRefresh: true,
            scrub: 2,
          }
        } )
  
        let bannerAnimateMobile = gsap.timeline( {
          scrollTrigger: {
            trigger: elem,
            start: 'top center',
            end: 'bottom top',
            scrub: 2,
          }
        } )

        ScrollTrigger.matchMedia({
          "(min-width: 768px)": function () {
            bannerAnimateDesktop.fromTo( $( elem ).find( '.info-container' ), { xPercent: -10 }, { xPercent: 0 }, 0)
            bannerAnimateDesktop.to( $( elem ).find( '.image-wrapper img' ), { xPercent: -5 }, 0)
            bannerAnimateDesktop.to( $( elem ).find( '.image-wrapper .blue-tri' ), { xPercent: 100 }, 0)
          },
    
          "(max-width: 767px)": function() {
            bannerAnimateMobile.to( $( elem ).find( '.image-wrapper img' ), { yPercent: -20 }, 0 )
            bannerAnimateMobile.to( $( elem ).find( '.image-wrapper .blue-tri' ), { xPercent: 100 }, 0 )
          }
        })
      } )
    }

    // Waterfall for cards

    if( $( '.hover-grid' ).length ) {
      let cardWaterfall = document.querySelectorAll( '.hover-grid' );
  
      // Vertical Marquee Animation
      function colorWaterfall( elem ) {
        ScrollTrigger.matchMedia({
          "(min-width: 768px)": function () {
            ScrollTrigger.create({
              trigger: elem,
              containerAnimation: scrollTween,
              invalidateOnRefresh: true,
              start:"top 70%", 
              onEnter: () => $(elem).addClass('waterfall'),
              onLeaveBack: () => $(elem).removeClass('waterfall'),
              onEnterBack: () => $(elem).addClass('waterfall'),
            });
          },
    
          "(max-width: 767px)": function() {
            ScrollTrigger.create({
              trigger: elem,
              start:"top center", 
              onEnter: () => $(elem).addClass('waterfall'),
              onLeaveBack: () => $(elem).removeClass('waterfall'),
              onEnterBack: () => $(elem).addClass('waterfall'),
            });
          }
        })
      }
      
      cardWaterfall.forEach( ( elem ) => {
        colorWaterfall( elem )
      } )
    }


  } ) 
}         

// ##     ##    ###    ########   #######  ##     ## ######## ########    ######## ######## ##     ## ######## 
// ###   ###   ## ##   ##     ## ##     ## ##     ## ##       ##             ##    ##        ##   ##     ##    
// #### ####  ##   ##  ##     ## ##     ## ##     ## ##       ##             ##    ##         ## ##      ##    
// ## ### ## ##     ## ########  ##     ## ##     ## ######   ######         ##    ######      ###       ##    
// ##     ## ######### ##   ##   ##  ## ## ##     ## ##       ##             ##    ##         ## ##      ##    
// ##     ## ##     ## ##    ##  ##    ##  ##     ## ##       ##             ##    ##        ##   ##     ##    
// ##     ## ##     ## ##     ##  ##### ##  #######  ######## ########       ##    ######## ##     ##    ##       
                                                                                                  
// Horizontal Marquee Animation
function horMarqueeText( elem ) {
  // Text scrolls on the x-axis
  let marquee = gsap.to( elem, {
    xPercent: -100,
    repeat: -1,
    duration: 28,
    ease: 'linear',
    paused: true,
  } ).totalProgress( 0.5 );

  ScrollTrigger.create({
    trigger: elem,
    start: 'top bottom',
    end: 'bottom top',
    invalidateOnRefresh: true,
    onEnter: () => marquee.play(),
    onEnterBack: () => marquee.play(),
    onLeave: () => marquee.pause(),
    onLeaveBack: () => marquee.pause(),
  })
  // User Scroll vs Marquee direction
  let currentScroll = 0;
  let isScrollingDown = true;

  window.addEventListener("scroll", function(){
  
    if ( window.pageYOffset > currentScroll ) {
      isScrollingDown = true;
    } else {
      isScrollingDown = false;
    }
      
    gsap.to( marquee, {
      timeScale: isScrollingDown ? 1 : -1
    });
    
    currentScroll = window.pageYOffset
  });
}

// Standalone Marquee Text Block
if( $( '.sliding-text' ).length ) {
  const slidingText = document.querySelectorAll( '.sliding-text' )

  slidingText.forEach( ( elem ) => {
    horMarqueeText( elem )
  } )
}

//  ######  #### ########  ########    ########  ##    ##     ######  #### ########  ######## 
// ##    ##  ##  ##     ## ##          ##     ##  ##  ##     ##    ##  ##  ##     ## ##       
// ##        ##  ##     ## ##          ##     ##   ####      ##        ##  ##     ## ##       
//  ######   ##  ##     ## ######      ########     ##        ######   ##  ##     ## ######   
//       ##  ##  ##     ## ##          ##     ##    ##             ##  ##  ##     ## ##       
// ##    ##  ##  ##     ## ##          ##     ##    ##       ##    ##  ##  ##     ## ##       
//  ######  #### ########  ########    ########     ##        ######  #### ########  ######## 
                                                                               
if( $( '.sbs-container' ).length ) {
  let imageTwo = document.querySelectorAll( '.sbs-container .image-wrapper-two .image-container img' )
  let imageTwoWrapper = document.querySelectorAll( '.sbs-container .image-wrapper-two .image-container' )
  let blueTri = document.querySelectorAll( '.sbs-container .col-wrapper .blue-triangle' )
  let darkTri = document.querySelectorAll( '.sbs-container .col-wrapper .dark-triangle' )
  let multiSBS = document.querySelectorAll( '.multi' )

  multiSBS.forEach( ( multiElem ) => {
    function triangleAnimation( elem, dx, mx, trigger ) {
      ScrollTrigger.matchMedia({
        "(min-width: 768px)": function () {
          gsap.to( elem, {
            yPercent: dx,
            scrollTrigger: {
              trigger: trigger,
              start: 'top center',
              end: 'center+=100 top',
              invalidateOnRefresh: true,
              scrub: 2,
            }
          } )
        },
  
        "(max-width: 767px)": function() {
          gsap.to( elem, {
            xPercent: mx,
            scrollTrigger: {
              trigger: trigger,
              start: 'top+=100 center',
              end: 'center+=100 top',
              scrub: 2,
            }
          } )
        }
      })
    }
  
    triangleAnimation( blueTri, -150, -150, multiElem )
    triangleAnimation( darkTri, -250, -150, multiElem )
  
    let sbsMultiAnimationDesktop = gsap.timeline( {
      scrollTrigger: {
        trigger: multiElem,
        start: 'top center+=200',
        end: 'center+=100 top',
        invalidateOnRefresh: true,
        scrub: 2
      }
    } )
  
    let sbsMultiAnimationMobile = gsap.timeline( {
      scrollTrigger: {
        trigger: multiElem,
        start: 'top+=100 center',
        end: 'bottom top',
        scrub: 2,
      }
    } )
  
    ScrollTrigger.matchMedia({ 
      "(min-width: 768px)": function () {
        sbsMultiAnimationDesktop.to( $( multiElem ).find( '.image-wrapper .image-container' ), { yPercent: -10 }, 0 )
        sbsMultiAnimationDesktop.to( $( multiElem ).find( '.col-wrapper .trees' ), { y: 300 }, 0 )
        
        imageTwoWrapper.forEach( ( elem, i ) => {
          sbsMultiAnimationDesktop.to( elem, { yPercent: 40  }, 0 )
        } )    
      },
  
      "(max-width: 767px)": function() {
        sbsMultiAnimationMobile.to( $( multiElem ).find( '.image-wrapper .image-container img' ), { yPercent: -10 }, 0 )
        sbsMultiAnimationMobile.to( $( multiElem ).find( '.col-wrapper .trees' ), { x: 300 }, 0 )
  
        imageTwo.forEach( ( elem, i ) => {
          sbsMultiAnimationMobile.to( elem, { yPercent: -20 * (0.75 / ( i + 1 )) } )
        } )
      }
    })
  } )

  // Single Side by side
  let imageTri = document.querySelectorAll( '.single .content-container .transparent-triangle' )
  let singleBlueTri = document.querySelectorAll( '.single .blue-triangle' )
  let singleSBS = document.querySelectorAll( '.single.outside-h-scroll-single' )

  singleSBS.forEach( ( singleElem ) => {
    let sbsSingleAnimationDesktop = gsap.timeline( {
      scrollTrigger: {
        trigger: singleElem,
        start: 'top center+=200',
        end: 'center+=100 top',
        invalidateOnRefresh: true,
        scrub: 2
      }
    } )
   
    if( $( singleElem ).find( '.sun' ).length ) {
      sbsSingleAnimationDesktop.fromTo( $( singleElem ).find( '.image-container img' ), { yPercent: -20 }, {yPercent: 0 }, 0 )
    } else {
      sbsSingleAnimationDesktop.to( $( singleElem ).find( '.image-container img' ), { yPercent: -20 }, 0 )
    }
    

    ScrollTrigger.matchMedia({ 
      "(min-width: 768px)": function () {
        if( $( singleElem ).find( '.sun' ).length ) {
          sbsSingleAnimationDesktop.fromTo( $( singleElem ).find( '.sun' ), { yPercent: 100 }, { yPercent: 0 }, 0 )
        }
        if( $( singleElem ).find( '.image-blue-triangle' ).length ) {
          sbsSingleAnimationDesktop.to( $( singleElem ).find( '.image-blue-triangle' ), { yPercent: -50 }, 0 )
        }
        if( $( singleElem ).find( '.image-dark-triangle' ).length ) {
          sbsSingleAnimationDesktop.to( $( singleElem ).find( '.image-dark-triangle' ), { yPercent: -100 }, 0 )
        }
        if( $( singleElem ).find( '.single-triangle' ).length ) {
          sbsSingleAnimationDesktop.to( $( singleElem ).find( '.single-triangle' ), { yPercent: 50 }, 0 )
        }
        if( $( singleElem ).find( '.drone' ).length ) {
          sbsSingleAnimationDesktop.fromTo( $( singleElem ).find( '.drone' ), { 
            yPercent: 150,
            xPercent: 150,
            rotate: 140,
            scale: 1.5,
          },{ 
            yPercent: 50,
            xPercent: 0,
            rotate: 0,
            scale: 1,
          }, 0 )
        }
      },
  
      "(max-width: 767px)": function() {
        if( $( singleElem ).find( '.sun' ).length ) {
          sbsSingleAnimationDesktop.to( $( singleElem ).find( '.sun' ), { xPercent: 100 }, 0 )
        }
        if( $( singleElem ).find( '.image-blue-triangle' ).length ) {
          sbsSingleAnimationDesktop.to( $( singleElem ).find( '.image-blue-triangle' ), { xPercent: -100 }, 0 )
        }
        if( $( singleElem ).find( '.image-dark-triangle' ).length ) {
          sbsSingleAnimationDesktop.to( $( singleElem ).find( '.image-dark-triangle' ), { xPercent: 50 }, 0 )
        }
        if( $( singleElem ).find( '.single-triangle' ).length ) {
          sbsSingleAnimationDesktop.to( $( singleElem ).find( '.single-triangle' ), { xPercent: -100 }, 0 )
        }
        if( $( singleElem ).find( '.drone' ).length ) {
          sbsSingleAnimationDesktop.fromTo( $( singleElem ).find( '.drone' ), { rotate: 180, xPercent: -200 }, { rotate: 0, xPercent: 0 }, 0 )
        }
      }
    })

    imageTri.forEach( ( elem ) => {
      gsap.to( elem, {
        left: 0,
        top: 0,
        scrollTrigger: {
          trigger: elem,
          start: 'top center',
          end: 'bottom top',
          invalidateOnRefresh: true,
          scrub: 1
        }
      } )
    } )
  
    singleBlueTri.forEach( ( elem ) => {
      gsap.to( elem, {
        right: 0,
        scrollTrigger: {
          trigger: elem,
          start: 'top center',
          end: 'bottom top',
          invalidateOnRefresh: true,
          scrub: 1
        }
      } )
    } )
  } )
}

//    ###    ##       ######## ######## ########  ##    ##    ###    ######## #### ##    ##  ######       ######  ########   ######  
//   ## ##   ##          ##    ##       ##     ## ###   ##   ## ##      ##     ##  ###   ## ##    ##     ##    ## ##     ## ##    ## 
//  ##   ##  ##          ##    ##       ##     ## ####  ##  ##   ##     ##     ##  ####  ## ##           ##       ##     ## ##       
// ##     ## ##          ##    ######   ########  ## ## ## ##     ##    ##     ##  ## ## ## ##   ####     ######  ########   ######  
// ######### ##          ##    ##       ##   ##   ##  #### #########    ##     ##  ##  #### ##    ##           ## ##     ##       ## 
// ##     ## ##          ##    ##       ##    ##  ##   ### ##     ##    ##     ##  ##   ### ##    ##     ##    ## ##     ## ##    ## 
// ##     ## ########    ##    ######## ##     ## ##    ## ##     ##    ##    #### ##    ##  ######       ######  ########   ######  

function SVGParallax( elem, trigger, amount ) {
  gsap.to( elem, {
    xPercent: amount,
    scrollTrigger: {
      trigger: trigger,
      start: 'top bottom',
      end: 'bottom top',
      invalidateOnRefresh: true,
      scrub: 2,
    }
  } )
}

if ( $( '.alternating-section' ).length ) {
  let alternatingImage = document.querySelectorAll( '.alternating-container .image-container img' )
  let alternatingSVG = document.querySelectorAll( '.alternating-section svg' )

  // Image Parallax
  function imageSBSParallax( elem, trigger, start, amount) {
    gsap.fromTo( elem, { yPercent: start }, {
      yPercent: amount,
      scrollTrigger: {
        trigger: trigger,
        start: 'top bottom',
        end: 'bottom top',
        invalidateOnRefresh: true,
        scrub: 2,
      }
    } )
  }

  alternatingImage.forEach( ( elem ) => {
    imageSBSParallax( elem, $( elem ).parents( '.alternating-container' ), 0, -20 )
  } )

  alternatingSVG.forEach( ( elem, i ) => {
    if( $( elem ).parent().parent().hasClass( 'reverse' ) && $( elem ).parent().parent().hasClass( 'alt' )  ) {
      SVGParallax( elem, $( elem ), 85 )
    } else if( $( elem ).parent().parent().hasClass( 'alt' )) {
      SVGParallax( elem, $( elem ), -85 )
    } else if( $( elem ).parent().parent().hasClass( 'reverse' )) {
      SVGParallax( elem, $( elem ), -100 )  
    } else {
      SVGParallax( elem, $( elem ), 100 )
    }  
  } )
}

// #### ##     ##    ###     ######   ########    ########     ###    ##    ## ##    ## ######## ########  
//  ##  ###   ###   ## ##   ##    ##  ##          ##     ##   ## ##   ###   ## ###   ## ##       ##     ## 
//  ##  #### ####  ##   ##  ##        ##          ##     ##  ##   ##  ####  ## ####  ## ##       ##     ## 
//  ##  ## ### ## ##     ## ##   #### ######      ########  ##     ## ## ## ## ## ## ## ######   ########  
//  ##  ##     ## ######### ##    ##  ##          ##     ## ######### ##  #### ##  #### ##       ##   ##   
//  ##  ##     ## ##     ## ##    ##  ##          ##     ## ##     ## ##   ### ##   ### ##       ##    ##  
// #### ##     ## ##     ##  ######   ########    ########  ##     ## ##    ## ##    ## ######## ##     ## 

if( $( '.image-banner-section .banner-container svg' ).length ) {
  let bannerSVG = document.querySelectorAll( '.image-banner-section svg' )

  bannerSVG.forEach( ( elem, i ) => {
    if( i == 0 ) {
      SVGParallax( elem, $( elem ).parent(), -100 )
    } else {
      SVGParallax( elem, $( elem ).parent(), 100 )
    }
  } )
}

// ##     ## ##    ##    ###    ##       ####  ######   ##    ## ######## ########      ######  ########   ######  
// ##     ## ###   ##   ## ##   ##        ##  ##    ##  ###   ## ##       ##     ##    ##    ## ##     ## ##    ## 
// ##     ## ####  ##  ##   ##  ##        ##  ##        ####  ## ##       ##     ##    ##       ##     ## ##       
// ##     ## ## ## ## ##     ## ##        ##  ##   #### ## ## ## ######   ##     ##     ######  ########   ######  
// ##     ## ##  #### ######### ##        ##  ##    ##  ##  #### ##       ##     ##          ## ##     ##       ## 
// ##     ## ##   ### ##     ## ##        ##  ##    ##  ##   ### ##       ##     ##    ##    ## ##     ## ##    ## 
//  #######  ##    ## ##     ## ######## ####  ######   ##    ## ######## ########      ######  ########   ######  

if( $( '.unaligned-sbs-section' ).length ) {
  let unalignedSBS = document.querySelectorAll( '.unaligned-sbs-section' )

  unalignedSBS.forEach( ( elem ) => {
    let sbsAnimate = gsap.timeline( {
      scrollTrigger: {
        trigger: elem,
        start: 'top+=100 center',
        end: 'center+=100 top',
        invalidateOnRefresh: true,
        scrub: 2,
      }
    } )

    sbsAnimate.to( $( elem ).find( '.sbs-svg' ), { xPercent: -50 }, 0 )

    

    ScrollTrigger.matchMedia({ 
      "(min-width: 768px)": function () {
        sbsAnimate.to( $( elem ).find( '.side-img-container' ), { yPercent: 20 }, 0 )
        sbsAnimate.to( $( elem ).find( '.lower-img-container' ), { yPercent: -25 }, 0 )
        sbsAnimate.to( $( elem ).find( '.side-img-container img' ), { yPercent: -10 }, 0 )
        sbsAnimate.to( $( elem ).find( '.lower-img-container img' ), { yPercent: -10 }, 0 )
      },
  
      "(max-width: 767px)": function() {
        sbsAnimate.to( $( elem ).find( '.side-img-container img' ), { yPercent: -10 }, 0 )
        sbsAnimate.to( $( elem ).find( '.lower-img-container img' ), { yPercent: -10 }, 0 )
      }
    })
  } )
}

//    ###     ######   ######   #######  ########  ########  ####  #######  ##    ## 
//   ## ##   ##    ## ##    ## ##     ## ##     ## ##     ##  ##  ##     ## ###   ## 
//  ##   ##  ##       ##       ##     ## ##     ## ##     ##  ##  ##     ## ####  ## 
// ##     ## ##       ##       ##     ## ########  ##     ##  ##  ##     ## ## ## ## 
// ######### ##       ##       ##     ## ##   ##   ##     ##  ##  ##     ## ##  #### 
// ##     ## ##    ## ##    ## ##     ## ##    ##  ##     ##  ##  ##     ## ##   ### 
// ##     ##  ######   ######   #######  ##     ## ########  ####  #######  ##    ## 

if( $( '.accordion-section' ).length ) {
  let accordionItem = document.querySelectorAll( '.accord' )
  let accordionInfo = document.querySelectorAll( '.accord-info' )
  let divHeights = []

  accordionItem.forEach( ( elem, i ) => {

    divHeights.push($(accordionInfo[i]).height());

    function addShow() {
      $( elem ).siblings().removeClass( 'active' ) 
      $( elem ).addClass( 'active' )
      $( accordionInfo[i] ).siblings().addClass( 'hidden' )
      $( accordionInfo[i] ).removeClass( 'hidden' )
      $('.red-blue-tri').attr( 'data-translate', 'translate-' + i)
    }

    elem.addEventListener( 'click', () => {
      addShow()
    } )
  } )

  divHeights.sort()
  let maxHeight = divHeights[divHeights.length - 1];
  $( '.accordion-info-container' ).css( 'min-height', maxHeight + 'px' );
}

// ########    ###     ######  ########  ######  
// ##         ## ##   ##    ##    ##    ##    ## 
// ##        ##   ##  ##          ##    ##       
// ######   ##     ## ##          ##     ######  
// ##       ######### ##          ##          ## 
// ##       ##     ## ##    ##    ##    ##    ## 
// ##       ##     ##  ######     ##     ######  

function showQuote() {

  let facts = $( '.facts' );
  let factsIndex = -1;
  let rotateAngle = 360 / $( '.facts' ).length

  function showNextQuote() {
    ++factsIndex;
    facts.eq(factsIndex % facts.length).fadeIn(1000).delay(1000).fadeOut(1000, showNextQuote);
    $( '.fact-tri' ).css( 'transform', `translate(-50%, -50%) rotate( ${rotateAngle * factsIndex}deg )` )
  }

  showNextQuote();

}

if( $( '.facts-section' ).length ) {
  let factsSection = document.querySelectorAll( '.facts-section' )

  factsSection.forEach( ( elem ) => {
    ScrollTrigger.create( {
      trigger: elem,
      start: 'top bottom',
      end: 'bottom top',
      invalidateOnRefresh: true,
      once: true,
      onEnter: () => showQuote(),
    } )
  } )
}

// ######## ########  #### ########  ##       ########    #### ##     ##    ###     ######   ######## 
//    ##    ##     ##  ##  ##     ## ##       ##           ##  ###   ###   ## ##   ##    ##  ##       
//    ##    ##     ##  ##  ##     ## ##       ##           ##  #### ####  ##   ##  ##        ##       
//    ##    ########   ##  ########  ##       ######       ##  ## ### ## ##     ## ##   #### ######   
//    ##    ##   ##    ##  ##        ##       ##           ##  ##     ## ######### ##    ##  ##       
//    ##    ##    ##   ##  ##        ##       ##           ##  ##     ## ##     ## ##    ##  ##       
//    ##    ##     ## #### ##        ######## ########    #### ##     ## ##     ##  ######   ######## 

if( $( '.triple-image' ).length ) {
  let tripleImage = document.querySelectorAll( '.triple-image' )

  tripleImage.forEach( ( elem ) => {
    let tripleAnimate = gsap.timeline( {
      scrollTrigger: {
        trigger: elem,
        start: 'top+=100 center',
        end: 'center+=100 top',
        invalidateOnRefresh: true,
        scrub: 2,
      }
    } )
    tripleAnimate.to( $( elem ).find( '.left-image img' ), { yPercent: -20 }, 0 )
    tripleAnimate.to( $( elem ).find( '.right-image img' ), { yPercent: -20 }, 0 )
    tripleAnimate.to( $( elem ).find( '.middle-image img' ), { yPercent: -20 }, 0 )
    tripleAnimate.to( $( elem ).find( '.middle-image' ), { yPercent: 20 }, 0 )
    tripleAnimate.to( $( elem ).find( '.left-image' ), { yPercent: 10 }, 0 )
    tripleAnimate.to( $( elem ).find( '.trees' ), { yPercent: 40 }, 0 )
    tripleAnimate.to( $( elem ).find( '.blue-tri' ), { yPercent: -20 }, 0 )
    tripleAnimate.to( $( elem ).find( '.dark-tri' ), { yPercent: 40 }, 0 )
  } )
}

// ##     ## ######## #### ##       #### ######## ##    ##    ##    ##    ###    ##     ## 
// ##     ##    ##     ##  ##        ##     ##     ##  ##     ###   ##   ## ##   ##     ## 
// ##     ##    ##     ##  ##        ##     ##      ####      ####  ##  ##   ##  ##     ## 
// ##     ##    ##     ##  ##        ##     ##       ##       ## ## ## ##     ## ##     ## 
// ##     ##    ##     ##  ##        ##     ##       ##       ##  #### #########  ##   ##  
// ##     ##    ##     ##  ##        ##     ##       ##       ##   ### ##     ##   ## ##   
//  #######     ##    #### ######## ####    ##       ##       ##    ## ##     ##    ###  

if( $( '.utility-nav-section' ).length ) {
  const nav = $('.nav');
  const lastLink = document.querySelector( '.last-link' )
  const lastLinkAttr = $( lastLink ).data('utility-nav');
  const lastSection = document.getElementById( lastLinkAttr )
  const lastSectionPosition = lastSection.getBoundingClientRect().top
  const utilityNav = document.querySelector( '.utility-nav-section' ) 
  const utilityNavPosition = utilityNav.getBoundingClientRect().top
  const navLink = document.querySelectorAll( '.util-nav-link' )
  let hScroll = document.querySelector( '.h-scroll' )

  ScrollTrigger.matchMedia({ 
    "(min-width: 768px)": function () {
      ScrollTrigger.create({
        trigger: hScroll,
        start: 'top top',
        end: () => "+=" + ( lastSectionPosition - utilityNavPosition + 10 ),
        toggleClass: { targets: nav, className: 'nav-lock' },
        onEnter: () => $( utilityNav ).addClass( 'fixed-nav' ),
        onEnterBack: () => $( utilityNav ).removeClass( 'hide-nav' ),
        onLeave: () => $( utilityNav ).addClass( 'hide-nav' ),
        onLeaveBack: () => $( utilityNav ).removeClass( 'fixed-nav' ),
        invalidateOnRefresh: true,
      })
      
      ScrollTrigger.create({
        trigger: hScroll,
        start: 'top top',
        end: () => '+=' + (hScroll.offsetWidth + document.documentElement.clientHeight) + ' top',
        toggleClass: { targets: utilityNav, className: 'hide-nav' }, 
        invalidateOnRefresh: true,
      })
    },

    "(max-width: 767px)": function() {
      ScrollTrigger.create({
        trigger: hScroll,
        start: 'top top',
        end: () => "+=" + ( lastSectionPosition + 10 ),
        onEnter: () => $( utilityNav ).addClass( 'fixed-nav' ),
        onEnterBack: () => $( utilityNav ).removeClass( 'hide-nav' ),
        onLeave: () => $( utilityNav ).addClass( 'hide-nav' ),
        onLeaveBack: () => $( utilityNav ).removeClass( 'fixed-nav' ),
        // markers: true,
      })
    }
  })
}

// Staggered Info Cards
if ( $( '.staggered' ).length ) {
  let staggeredCards = document.querySelectorAll( '.staggered-container .card' )
  let triangleAnim = document.querySelectorAll( '.staggered-container .svg-animate' )

  staggeredCards.forEach( ( elem, i ) => {
    ScrollTrigger.matchMedia({ 
      "(min-width: 768px)": function () {
        let cardAnimate = gsap.timeline( {
          scrollTrigger: {
            trigger: $( elem ).parents( '.staggered' ),
            start: 'top-=100 bottom-=200',
            end: 'bottom+=200 bottom-=200',
            invalidateOnRefresh: true,
            scrub: 2,
          }
        } )
        if ( i % 2 == 0 ) {
          cardAnimate.to( $( elem ), { yPercent: -5} )
        } else {
          cardAnimate.to( $( elem ), { yPercent: 5 } )
        }
      }
    })
  } )

  triangleAnim.forEach( ( elem ) => {
    let triangleAnimate = gsap.timeline( {
      scrollTrigger: {
        trigger: elem,
        yPercent: 20,
        start: 'top-=100 bottom-=300',
        end: 'bottom+=500 bottom-=300',
        invalidateOnRefresh: true,
        scrub: 2, 

      }
    } )
    if( $( elem ).hasClass( 'right-out' ) || $( elem ).hasClass( 'right-in' )) {
      triangleAnimate.to( $( elem ), { xPercent: -50 }, 0 )
    }
    if( $( elem ).hasClass( 'left-out' ) || $( elem ).hasClass( 'left-in' ) ) {
      triangleAnimate.to( $( elem ), { xPercent: 50 }, 0 )
    }
  } )
  
}

if ( $( '[data-fadein]' ).length ) {
  $( window ).on( 'load', function() {
    ScrollTriggerInit;
    let fadeIn = document.querySelectorAll( '[data-fadein]' );

    fadeIn.forEach((elem, i) => {
      ScrollTrigger.create({
        trigger: elem,
        start:"top bottom-=5%",
        onEnter: () => $(elem).addClass('fade-in'),
        onLeaveBack: () => $(elem).removeClass('fade-in'),
        onEnterBack: () => $(elem).addClass('fade-in'),
        invalidateOnRefresh: true,
      });
    });
  });
}

// ########  ######## ######## ########  ########  ######  ##     ## 
// ##     ## ##       ##       ##     ## ##       ##    ## ##     ## 
// ##     ## ##       ##       ##     ## ##       ##       ##     ## 
// ########  ######   ######   ########  ######    ######  ######### 
// ##   ##   ##       ##       ##   ##   ##             ## ##     ## 
// ##    ##  ##       ##       ##    ##  ##       ##    ## ##     ## 
// ##     ## ######## ##       ##     ## ########  ######  ##     ## 

if( $( '.js-sort' ).length ) {
  $( '.js-sort button' ).on( 'click', function ( e ) {
    setTimeout( () => {
      ScrollTrigger.refresh();
    }, 100 )
  })
}

$( window ).on( 'load', () => {
  setTimeout( () => {
    ScrollTrigger.refresh();
  }, 100 )
})

if( $( '.scrub-1' ).length ) {
  let scrubOne = document.querySelectorAll( '.scrub-1' )

  scrubOne.forEach( ( elem ) => { 
    let anim = gsap.timeline( {
      scrollTrigger: {
        trigger: elem,
        start: 'top+=100 center',
        end: 'center+=100 top',
        invalidateOnRefresh: true,
        scrub: 1, 
      }
    } )
    anim.to( $( elem ).find( '.double-triangle-down' ), { yPercent: 40 }, 0 )
    anim.to( $( elem ).find( '.double-triangle-up' ), { yPercent: -60 }, 0 )
  } )
}
if( $( '.tri-in' ).length ) {
  let scrubOne = document.querySelectorAll( '.fit-and-finishes' )

  scrubOne.forEach( ( elem ) => { 
    let anim = gsap.timeline( {
      scrollTrigger: {
        trigger: elem,
        start: 'top+=100 center',
        end: 'center+=100 top',
        invalidateOnRefresh: true,
        scrub: 1, 
      }
    } )
    anim.to( $( elem ).find( '.tri-in' ), { yPercent: -40 }, 0 )
    anim.to( $( elem ).find( '.tri-out' ), { yPercent: -60 }, 0 )
  } )
}
if( $( '.scrub-1' ).length ) {
  let scrubOne = document.querySelectorAll( '.scrub-1 ' )

  scrubOne.forEach( ( elem ) => { 
    let anim = gsap.timeline( {
      scrollTrigger: {
        trigger: elem,
        start: 'top+=100 center',
        end: 'center+=100 top',
        invalidateOnRefresh: true,
        scrub: 1, 
      }
    } )
    anim.to( $( elem ).find( '.double-triangle-down' ), { yPercent: 40 }, 0 )
    anim.to( $( elem ).find( '.double-triangle-up' ), { yPercent: -60 }, 0 )
  } )
}
//not sure what this is supposed to do
// if( $( '.rental-override' ).length ) {
//   let sbsRental = document.querySelectorAll( '.rental-override' )

//   sbsRental.forEach( ( elem ) => { 
//     let anim = gsap.timeline( {
//       scrollTrigger: {
//         trigger: elem,
//         start: 'top+=100 center',
//         end: 'center+=100 top',
//         invalidateOnRefresh: true,
//         scrub: 1, 
//       }
//     } )
//     anim.to( $( elem ).find( '.fill-odd' ), { xPercent: -40 }, 0 )
//     anim.to( $( elem ).find( '.fill-even' ), { xPercent: 40 }, 0 )
//   } )
// }

if( $( '.utility-nav-section-2' ).length ) {
  let nav = $('.nav');
  let trigger = document.getElementById( 'main-content' )
  let utilityNav = document.querySelector( '.utility-nav-section-2' ) 

  ScrollTrigger.matchMedia({ 
    "(min-width: 768px)": function () {
      $(utilityNav).addClass( 'override' )
      ScrollTrigger.create({
        trigger: trigger,
        start: 'top top',
        end: 'bottom bottom',
        toggleClass: { targets: nav, className: 'nav-lock' },
        onEnter: () => $( utilityNav ).addClass( 'fixed-nav' ),
        onEnterBack: () => $( utilityNav ).removeClass( 'hide-nav' ),
        onLeave: () => $( utilityNav ).addClass( 'hide-nav' ),
        onLeaveBack: () => $( utilityNav ).removeClass( 'fixed-nav' ),
        invalidateOnRefresh: true,
      })
    },

    "(max-width: 767px)": function() {
      $(utilityNav).removeClass( 'override' )
      ScrollTrigger.create({
        trigger: trigger,
        start: 'top top',
        end: 'top bottom',
        onEnter: () => $( utilityNav ).addClass( 'fixed-nav' ),
        onEnterBack: () => $( utilityNav ).removeClass( 'hide-nav' ),
        onLeave: () => $( utilityNav ).addClass( 'hide-nav' ),
        onLeaveBack: () => $( utilityNav ).removeClass( 'fixed-nav' ),
      })
    }
  })
}