/**
 * External Dependencies, short list is needed on every page
 */

 import './components/nav';
 import './components/anim';
 import './components/accordion';
 import './components/glide';
 import './components/modal';
 import LazyLoad from "vanilla-lazyload";

//One offs
// Lazyload images
var myLazyLoad = new LazyLoad();