import $ from 'jquery';

// a basic accordion.
if( $( '.accordion' ).length ) {
  $(function() {
    $('.accordion .title').on( 'click', function(e) {
      e.preventDefault();
  
      var $this = $(this);
      var $accordion = $this.parent();
      var $content = $this.next();
      var $height = 66;

      if( $accordion.prev().length ) {
        var $position = $accordion.prev().position().top + $( '.map-legend' ).scrollTop() - ($height * 2) 
        //this gets the top value of the previous accordion + the amount the legend has scrolled 
        //- the height * 2 show it shows the previous accordion option
        $( '.map-legend' ).animate( {
          scrollTop: $position
        }, 250 )
      } else {
        var $position = $this.position().top - $content[0].scrollHeight - $height
        //for the first accordion item, it's grabbing the position top, minusing the height of its 
        //content container and the height of the title
        $( '.map-legend' ).animate( {
          scrollTop: $position
        }, 250 )
      }

      $accordion.parent().find('.text-content').css('max-height', '0');
  
      if ($accordion.hasClass('open')) {
        $accordion.removeClass('open');
      } else {
        $accordion.siblings().removeClass('open');
        $accordion.addClass('open');
        $content.css('max-height', $content[0].scrollHeight);
      }    
    });
  })
}
