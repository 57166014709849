import Cookies from 'js-cookie';
import $ from 'jquery';

function enableScroll() {
  $( 'body' ).removeClass( 'no-scroll' );
}
function animateClose() {
  setTimeout( () => {
    //does nav animation & removes the cookie
    $( '.nav' ).removeClass( 'open' )
    Cookies.remove( 'nav_cook');
  }, 200 )  
  setTimeout( () => {
    //does hero animation
    $( '.hero' ).addClass( 'nav-loaded' )
  }, 400 )  
  setTimeout( () => {
    enableScroll()
  }, 500 )
}

//create a cookie that is passed from the previous page to the new page
//to show the correct header img when the nav loads
//only set when using the nav
$( '.nav-cook' ).on( 'click', function ( ) {
  Cookies.set( 'nav_cook', 'true' );
}); 

//this runs when using the nav to switch pages
if ( Cookies.get( 'nav_cook' ) == 'true' ) {
  //show if there's cookies so we can do the nav animation
  if( $( '.hero-img img' ).length ) {
    //waits for the hero img to load then runs the animation
    $( '.hero-img img' ).on( 'load', function() {
      animateClose();
    })
    //if the background image is missing it will still close the nav
    if( $( '.hero-img img.error' ) ) {
      animateClose();
    }
  }
} else {
  //enables scroll and animates hero when nav cookie hasn't been set
  $( '.hero' ).addClass( 'nav-loaded' );
  enableScroll(); 
}


if( $( '.nav' ).length ) {
  $( '.nav-toggle' ).on( 'click', () => {
    $( 'html, body' ).animate( { scrollTop: 0 } );
    $( '.nav' ).toggleClass( 'open' )
    $( 'body' ).toggleClass( 'no-scroll' );
  } )
  $( '.close-nav' ).on( 'click', () => {
    $( '.nav' ).removeClass( 'open' )
    $( '.hero' ).addClass( 'nav-loaded' )
    enableScroll();
  });
}

//this connects the header photo for each page 
//with the menu item for the nav hover event
if( $( '.menu-photo' ).length ) {
  $( '.menu-item-with-photo' ).hover( function() {
    var data = $( this ).data( 'photo' );

    $( '.menu-photo-default' ).toggleClass( 'hover-show' );
    $('[data-photo="'+ data +'"]').toggleClass( 'hover-show' );
  }) 
}

var position = $( window ).scrollTop(); 
var navHeight = $( '.nav' ).height();
var headerHeight = $( '.page-header' ).outerHeight() + navHeight;
// should start at 0

$( window ).on( 'scroll', () => {

  var scroll = $( window ).scrollTop();
  //if the user scrolls past the navigation height, adds position fixed 
  // and translates the menu -100%;
  if ( scroll >= navHeight ) { 
    $( '.nav' ).addClass( 'nav-fix' );
  } else {
    $( '.nav' ).removeClass( 'nav-fix' );
  }
  // when the user scrolls page the hero, adds a nav-animate class so 
  // the fixed nav can now animate
  if( scroll > position || scroll <= navHeight) { 
    $( '.nav' ).removeClass( 'nav-animate' );
  } else {
    $( '.nav' ).addClass( 'nav-animate' );
  }
  position = scroll;
  // when the user makes an upwards scroll the nav translates to 0% 
  // if it has the nav-animate class also
  if ( scroll >= headerHeight ) {
    $( '.nav' ).addClass( 'nav-show' );
  } else {
    $( '.nav' ).removeClass( 'nav-show' );
  }
});

window.dataLayer = window.dataLayer || [];

$( '#mc-embedded-subscribe' ).on( 'click', () => {
  dataLayer.push( { 'event': 'register_complete', 'value': 'complete' } );
})

$( '#contact-us' ).on( 'change', () => {
  const select = document.querySelector('.select')
  const form = document.contactForm

  const baseURL = 'https://formsubmit.co/';
  let actionURL = '';
  switch(select.value) {
    case 'Brighton Rentals':
      actionURL = baseURL + '98517b82fda328c1747ad1af42f34289';
      break;
    case 'General Community':
      actionURL = baseURL + '5987c492978159026b75c189b821c2e5';
      break;
    case 'Retail Leasing':
      actionURL = baseURL + '7369fdacb1459be5dd2b432938628369';
      break;
  }

  form.action = actionURL;
})

$( 'form' ).on( 'submit', () => { 
  dataLayer.push( { 'event': 'contact_form', 'value': 'complete' } );
})
 