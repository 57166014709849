import $ from 'jquery';
var $modal = $( '[data-modal]' );

function updateModal( hash ) {
  if (  hash && hash.startsWith( '#!' )  ) {
    if (  hash !== '#!'  ) {
      $( 'body' ).addClass( 'no-scroll' );
    } else {
      $( 'body' ).removeClass( 'no-scroll' );
    }
  }
}

if ( $modal.length ) {
  $( window ).on( 'hashchange', function() {
    updateModal( location.hash );
  });
  updateModal( location.hash );
  
  setTimeout( () => {
    $( $modal ).addClass( 'visible' );
  }, 100 )  
}
